import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const NotFoundPage = () => {
  const navigate = useNavigate(); // Use navigate to programmatically go to home

  const handleGoHome = () => {
    navigate('/'); // Navigate back to the homepage
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white text-gray-800">
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <p className="text-xl mb-8">Oops! The page you're looking for doesn't exist.</p>
      <button
        onClick={handleGoHome}
        className="bg-blue-500 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-600 transition"
      >
        Go Home
      </button>
    </div>
  );
};

export default NotFoundPage;
