import React from 'react';
import { getAuth } from 'firebase/auth'; // Import getAuth correctly
import { useAuth } from '../AuthContext';

const ProfilePage = () => {
  const { currentUser } = useAuth();
  const auth = getAuth();

  const logout = () => {
    auth.signOut();
  };

  return (
    <div className="container mx-auto p-6">
      <div className="bg-white shadow-md p-6 rounded-lg border border-gray-200 max-w-lg mx-auto">
        <h1 className="text-2xl font-bold mb-4">Profile</h1>
        <div className="mb-4">
          <p className="text-lg font-semibold">User: </p>
          <p className="text-gray-600">{currentUser?.email || 'No user logged in'}</p>
        </div>
        <button
          onClick={logout}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default ProfilePage;
