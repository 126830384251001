import React from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom'; // Use useNavigate

const LoginPage = () => {
  const { signInWithApple } = useAuth(); // Get signInWithApple from context
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSignIn = () => {
    signInWithApple()
      .then(() => {
        // Navigate to profile page after successful login
        navigate('/profile');
      })
      .catch((error) => {
        console.error('Error during Apple Sign-In:', error);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="bg-slate-100 border shadow-lg p-8 rounded-lg max-w-md w-full text-center">
        <h1 className="text-3xl font-bold mb-6">Login</h1>
        <button
          onClick={handleSignIn}
          className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 transition w-full"
        >
          Login with Apple
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
