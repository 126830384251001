import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/login');
  };

  return (

      <div className="min-h-screen flex flex-col bg-white text-white">
        {/* Hero Section with Background Image */}
        <div
          className="relative flex-grow flex flex-col items-center justify-center text-center py-60 bg-cover bg-center"
          style={{
            backgroundImage:
              'url(https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg)',
            //   'url(https://images.pexels.com/photos/796602/pexels-photo-796602.jpeg)',
          }}
        >
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10">
            <h1 className="text-5xl font-bold mb-4">Focus on What Matters with TaskUno</h1>
            <p className="text-lg mb-8">
              Manage one task at a time with simple swipe gestures to complete or skip tasks. Stay focused and productive with TaskUno.
            </p>
            <button
              onClick={handleGetStarted}
              className="bg-white text-blue-500 px-6 py-3 rounded-md font-semibold hover:bg-gray-100 transition"
            >
              Get Started
            </button>
          </div>
        </div>

        {/* Features Section */}
        <div className="bg-white text-gray-800 py-12">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-8">Why TaskUno is Different</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-6 shadow-md border rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Focus on One Task</h3>
                <p>TaskUno's unique approach helps you focus on one task at a time. Swipe right to complete a task or left to skip and tackle it later.</p>
              </div>
              <div className="p-6 shadow-md border rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Prioritize Your Workflow</h3>
                <p>Easily set priorities for your tasks and let TaskUno present them one-by-one, keeping you from feeling overwhelmed.</p>
              </div>
              <div className="p-6 shadow-md border rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Mobile-First Productivity</h3>
                <p>With a mobile-first design, TaskUno empowers you to stay productive on the go, ensuring you can focus on what matters most anytime, anywhere.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="bg-slate-500 py-12">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Master Your Task List?</h2>
            <p className="text-lg mb-8">
              Join TaskUno and experience a simpler, more focused way to manage your tasks.
            </p>
            <button
              onClick={handleGetStarted}
              className="bg-blue-500 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-600 transition"
            >
              Get Started for Free
            </button>
          </div>
        </div>
      </div>
    
  );
};

export default HomePage;
