import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

const BulkImportPage = () => {
  const { projectId } = useParams();
  const [taskText, setTaskText] = useState('');
  const [taskPreview, setTaskPreview] = useState([]);

  // Load taskText from localStorage on mount
  useEffect(() => {
    const savedText = localStorage.getItem('bulkImportTaskText');
    if (savedText) {
      setTaskText(savedText);
      setTaskPreview(parseTasks(savedText));
    }

    // Prompt before closing the tab
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // Display a default warning message
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Save taskText to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('bulkImportTaskText', taskText);
  }, [taskText]);

  const parseTasks = (text) => {
    const lines = text.split('\n');
    const tasks = [];
    let currentTask = null;

    lines.forEach((line) => {
      if (line.trim() === '') return;
      if (!line.startsWith('\t')) {
        if (currentTask) {
          tasks.push(currentTask);
        }
        currentTask = { title: line.trim(), description: '' };
      } else if (currentTask) {
        currentTask.description += line.trim() + ' ';
      }
    });

    if (currentTask) {
      tasks.push(currentTask);
    }

    return tasks;
  };

  const handleTaskTextChange = (e) => {
    const text = e.target.value;
    setTaskText(text);
    setTaskPreview(parseTasks(text));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const { selectionStart, selectionEnd } = e.target;
      const value = taskText;
      const newValue = value.substring(0, selectionStart) + '\t' + value.substring(selectionEnd);
      setTaskText(newValue);
      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
      }, 0);
    }
  };

  const bulkImportTasks = async () => {
    if (!projectId) {
      console.error("Error: projectId is not defined.");
      return;
    }

    const tasks = parseTasks(taskText);
    const projectRef = doc(firestore, 'projects', projectId);

    const formattedTasks = tasks.map((task, index) => ({
      title: task.title,
      description: task.description || '',
      completed: false,
      id: new Date().getTime() + index,
      priority: 'Low',
    }));

    try {
      await updateDoc(projectRef, {
        tasks: arrayUnion(...formattedTasks),
      });
      setTaskText('');
      setTaskPreview([]);
      localStorage.removeItem('bulkImportTaskText');
    } catch (error) {
      console.error('Error importing tasks:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Bulk Import Tasks</h1>

      <div className="grid grid-cols-2 gap-8">
        <div>
          <textarea
            value={taskText}
            onChange={handleTaskTextChange}
            onKeyDown={handleKeyDown}
            rows="15"
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter tasks. Each new line without tabs will be a task. Lines starting with tabs will be the description."
          />
          <button
            onClick={bulkImportTasks}
            className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 hover:bg-blue-600"
          >
            Import Tasks
          </button>
        </div>

        <div className="bg-gray-50 p-4 rounded-md border border-gray-300">
          <h2 className="text-xl font-bold mb-4">Task Preview</h2>
          {taskPreview.length > 0 ? (
            <ul className="space-y-4">
              {taskPreview.map((task, index) => (
                <li key={index} className="border-b pb-2">
                  <h3 className="font-semibold">{task.title}</h3>
                  {task.description && (
                    <p className="text-sm text-gray-600">{task.description}</p>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No tasks to preview</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkImportPage;
