import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { firestore } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import updateDoc to update the document

const TaskPage = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ title: '', priority: 'Low' }); // State for new task
  const { projectId } = useParams(); // Get the projectId from the URL
  const navigate = useNavigate(); // Use navigate for programmatic navigation

  useEffect(() => {
    const fetchTasks = async () => {
      if (projectId) {
        // Reference the specific project document
        const projectRef = doc(firestore, 'projects', projectId);
        const projectSnapshot = await getDoc(projectRef); // Fetch the project document

        if (projectSnapshot.exists()) {
          const projectData = projectSnapshot.data();
          const sortedTasks = projectData.tasks
            ? [...projectData.tasks].sort((a, b) => {
                if (a.completed === b.completed) {
                  return b.id - a.id; // Sort by id (newest first)
                }
                return a.completed - b.completed; // Sort incomplete tasks first
              })
            : [];
          setTasks(sortedTasks); // Set the sorted tasks
        } else {
          console.error('Project does not exist');
        }
      }
    };

    fetchTasks();
  }, [projectId]);

  // Handle new task input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  // Add new task to the project
  const handleAddTask = async (e) => {
    e.preventDefault();

    if (newTask.title.trim()) {
      const updatedTasks = [
        ...tasks,
        {
          ...newTask,
          completed: false,
          id: tasks.length + 1, // Simple way to assign new ID
        },
      ];

      const projectRef = doc(firestore, 'projects', projectId);
      await updateDoc(projectRef, { tasks: updatedTasks }); // Update the tasks array in Firestore

      // Update local state with the sorted tasks after adding the new one
      const sortedTasks = updatedTasks.sort((a, b) => {
        if (a.completed === b.completed) {
          return b.id - a.id; // Sort by id (newest first)
        }
        return a.completed - b.completed; // Sort incomplete tasks first
      });

      setTasks(sortedTasks); // Update the local state
      setNewTask({ title: '', priority: 'Low' }); // Reset the form
    }
  };

  // Function to navigate to Bulk Import page
  const handleBulkImportClick = () => {
    navigate(`/bulk-import/${projectId}`); // Navigate to bulk import page with projectId
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
        <a href='/projects' className="text-blue-500 mr-2">&lt;</a>
        Tasks
      </h1>

      {/* Add New Task Form */}
      <form onSubmit={handleAddTask} className="flex space-x-4 mb-6">
        <input
          type="text"
          name="title"
          value={newTask.title}
          onChange={handleInputChange}
          placeholder="Task Title"
          required
          className="border border-gray-300 p-2 rounded-md w-64"
        />
        <select
          name="priority"
          value={newTask.priority}
          onChange={handleInputChange}
          className="border border-gray-300 p-2 rounded-md"
        >
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          Add Task
        </button>
      </form>

      {/* Bulk Import Button */}
      <div className="mb-6">
        <button
          onClick={handleBulkImportClick} // On click, navigate to Bulk Import page
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
        >
          Bulk Import Tasks
        </button>
      </div>

      {/* Tasks Row Layout */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {tasks.length > 0 ? (
          tasks.map((task) => (
            <div
              key={task.id}
              className={` shadow-md p-4 rounded-lg border ${
                task.completed ? 'border-green-500 bg-green-50' : 'border-gray-200 bg-white'
              }`}
            >
              <h3 className="text-lg font-semibold">{task.title}</h3>
              <p className="text-sm text-gray-600">Priority: {task.priority}</p>
              <p className="text-sm text-gray-600">
                Completed: {task.completed ? 'Yes' : 'No'}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No tasks found for this project.</p>
        )}
      </div>
    </div>
  );
};

export default TaskPage;
