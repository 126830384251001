// firebase.js
import { initializeApp } from 'firebase/app'; // Correct modular import
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_IWxbTyKKHLDQyOeYHIlhkYPRIVvO7ao",
  authDomain: "funtaskticapp.firebaseapp.com",
  projectId: "funtaskticapp",
  storageBucket: "funtaskticapp.appspot.com",
  messagingSenderId: "76110253694",
  appId: "1:76110253694:web:d55760bf44cbd460c9d22f"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export default app;